import React, { useState } from "react";
import { connect } from "react-redux";
import "./Auth.css";
import { Link } from "react-router-dom";
import { Modal, Button, Image } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import SocialButton from "../../helper/SocialButton";
import {
    userRegisterStart,
    usernameValidationStart,
    referralValidationStart,
} from "../../../store/actions/UserAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import SignInModal from "../Auth/SignInModal";

const SignUpModal = (props) => {

    const [signInModal, setSignInModal] = useState(false);

    const closeSignInModal = () => {
        setSignInModal(false);
    };


    const [signupInputData, setSignupInputData] = useState({});
    const [isvalidUserName, setIsValidUserName] = useState(false)
    const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
    const [referralCode, setReferralCode] = useState("");
    const [userName, setUserName] = useState("");

    const handleSignup = (signupData) => {
        props.dispatch(userRegisterStart(signupData));
    };

    const checkReferralCode = (event) => {
        event.preventDefault();

        if (referralCode) {
            props.dispatch(referralValidationStart({ referral_code: referralCode }));
        } else {
            const notificationMessage = getErrorNotificationMessage('Please enter the Referral code');
            props.dispatch(createNotification(notificationMessage));
        }
    };

    const handleUsernameValidation = (username) => {
        if (username && username.length > 3) {
            if (username.replace(" ", "") === username) {
                if (username !== userName) {
                    setUserName(username);
                    setIsValidUserName(true);
                    props.dispatch(usernameValidationStart({ username: username }));
                    return "";
                }
            }
            else {
                setIsValidUserName(false);
                return "No white space allowed";
            }
        } else {
            setIsValidUserName(false);
            return "Must Contain 4 Characters";
        }
    };

    const handleFacebookLogin = (user) => {
        console.log("handleFacebookLogin", user._profile);
        props.dispatch(
            userRegisterStart({
                name: user._profile.name,
                first_name: user._profile.firstName ? user._profile.firstName : "",
                last_name: user._profile.lastName ? user._profile.lastName : "",
                email: user._profile.email ? user._profile.email : "",
                social_unique_id: user._profile.id,
                picture: user._profile.profilePicURL,
                login_by: "facebook",
            })
        );
    };

    const registerSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required *"),
        username: Yup.string()
            .required("Username is required *"),
        email: Yup.string()
            .email("Invalid email address format")
            .required("Email is required *"),
        password: Yup.string()
            .required("Password is required *")
            .matches(
                /^(?=.*[a-zA-Z0-9])(?=.{6,})/,
                "Must Contain 6 Characters"
            ),
    });

    const handleGoogleLogin = (user) => {
        console.log("handleGoogleLogin", user._profile);
        props.dispatch(
            userRegisterStart({
                name: user._profile.name,
                email: user._profile.email,
                first_name: user._profile.firstName ? user._profile.firstName : "",
                last_name: user._profile.lastName ? user._profile.lastName : "",
                social_unique_id: user._profile.id,
                picture: user._profile.profilePicURL,
                login_by: "google",
            })
        );
    };

    const handleSocialLoginFailure = (err) => {
        console.error(err);
    };

    return (
        <>
            <Modal
                show={props.signUpModal}
                onHide={props.closeSignUpModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="auth-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="signin-modal form-section">
                        <Link to="#" className="sign-in-logo">
                            <Image
                                src={
                                    window.location.origin +
                                    "/assets/images/logo-get-my-content.png"
                                }
                                alt=""
                                className="modal-logo"
                            />
                        </Link>
                        {configuration.get("configData.FB_CLIENT_ID") ? (
                            <SocialButton
                                provider="facebook"
                                appId={configuration.get("configData.FB_CLIENT_ID")}
                                onLoginSuccess={handleFacebookLogin}
                                onLoginFailure={handleSocialLoginFailure}
                                className="social-button"
                                id="facebook-connect"
                            >
                                <span>Sign In / Login with Facebook</span>
                            </SocialButton>
                        ) : (
                            ""
                        )}

                        {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                            <SocialButton
                                provider="google"
                                key={"google"}
                                appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                                onLoginSuccess={handleGoogleLogin}
                                onLoginFailure={handleSocialLoginFailure}
                                className="social-button"
                                id="google-connect"
                            >
                                <span>Sign Up / Login with Google</span>
                            </SocialButton>
                        ) : (
                            ""
                        )}

                        {/* <Button className="social-button" id="facebook-connect">
                            <span>Sign Up / Login with Facebook</span>
                        </Button>
                        <Button className="social-button" id="google-connect">
                            <span>Sign Up / Login with Google</span>
                        </Button> */}
                        {configuration.get("configData.GOOGLE_CLIENT_ID") || configuration.get("configData.FB_CLIENT_ID") ? (
                            <span className="or-line"><span>or</span></span>
                        ) : (
                            ''
                        )}

                        <Formik
                            initialValues={{
                                name: "",
                                username: "",
                                email: "",
                                password: "",
                            }}
                            validationSchema={registerSchema}
                            onSubmit={(values) => handleSignup(values)}>

                            {({ touched, errors, isSubmitting, setFieldValue }) => (
                                <Form noValidate>
                                    {/* <ConnectedFocusError /> */}
                                    <div class="form-group">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            className="form-control"
                                            autocomplete="off" />
                                        <ErrorMessage component={"div"} name="name" className='text-danger text-right' />
                                    </div>

                                    <div class="form-group">
                                        <Field
                                            type="text"
                                            name="username"
                                            placeholder="User Name"
                                            className="form-control"
                                            validate={handleUsernameValidation}
                                            autocomplete="off"
                                        />
                                        <ErrorMessage component={"div"} name="username" className='text-danger text-right' />
                                        {props.validation.isInValid && isvalidUserName ? (
                                            <div class="text-danger text-right">
                                                {t("username_already_taken")}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {props.validation.isValid && isvalidUserName ? (
                                            <div class="text-success text-right">
                                                {t("looks_good")}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div class="form-group">
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="E-mail Address"
                                            className="form-control mb-3"
                                            autocomplete="off" />
                                        <ErrorMessage component={"div"} name="email" className='text-danger text-right' />
                                    </div>

                                    <div className="form-group">
                                        <div class="input-group">
                                            <Field
                                                type={loginPasswordVisible ? "text" : "password"}
                                                name="password"
                                                placeholder="Password"
                                                className="form-control mb-3"
                                                autocomplete="off"
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    onClick={() => setLoginPasswordVisible(!loginPasswordVisible)}
                                                    class="btn password-eye"
                                                    type="button">
                                                    {loginPasswordVisible ?
                                                        <i className="fas fa-eye-slash align-self-center"></i>
                                                        : <i className="fas fa-eye align-self-center"></i>}
                                                </button>
                                            </div>
                                        </div>
                                        <ErrorMessage component={"div"} name="password" className='text-danger text-right' />
                                    </div>

                                    {configuration.get("configData.is_referral_enabled") == 1 ? (
                                        <>
                                            <div class="form-group">
                                                <Field
                                                    type="text"
                                                    name="referral_code"
                                                    placeholder="Referral Code(Optional)"
                                                    value={referralCode}
                                                    className="form-control mb-3"
                                                    onChange={e => setReferralCode(e.target.value)} />
                                                <ErrorMessage component={"div"} name="referral_code" className='text-danger text-right' />
                                                <div className="check-referral-link">
                                                    <a
                                                        className="text-primary"
                                                        href="#"
                                                        onClick={checkReferralCode}
                                                    >
                                                        {t("check_referral_code_valid")}
                                                    </a>
                                                </div>
                                            </div>
                                        </>) : null}
                                    <div className="round">
                                        <p className="terms text-center">
                                            {t("signing_up_confirmation")}{" "}
                                            <br></br>
                                            <Link to={`/page/terms`} target="_blank">
                                                {t("terms_of_service")}
                                            </Link>{" "}
                                            {t("and")}{" "}
                                            <Link to={`/page/privacy`} target="_blank">
                                                {t("privacy_policy")}
                                            </Link>
                                            .
                                        </p>
                                    </div>

                                    <Button
                                        type="submit"
                                        className="btn gradient-btn gradientcolor"
                                        disabled={props.signup.buttonDisable}
                                    >
                                        {props.signup.loadingButtonContent !== null
                                            ? props.signup.loadingButtonContent
                                            : "Sign Up"}
                                    </Button>

                                    <p id="two">{t("already_have_an_account")}</p>
                                    <p>
                                        <Link
                                            className="signup"
                                            href="#"
                                            id="signin"
                                            onClick={() => setSignInModal(true)}
                                        >
                                            {" "}
                                            {t("login_for")}{" "}
                                            {configuration.get("configData.site_name")}
                                        </Link>
                                    </p>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
            <SignInModal signInModal={signInModal} closeSignInModal={closeSignInModal} />
        </>
    );
};

const mapStateToPros = (state) => ({
    signup: state.users.registerInputData,
    validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(SignUpModal));
